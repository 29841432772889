import { analyticsTrack } from '@pumpkincare/portal-analytics';
import { CAT, formatCurrency, PET_AGE_OPTIONS } from '@pumpkincare/portal-shared';
import { ButtonPrimary } from '@pumpkincare/portal-ui';

import formatQuoteFlowRoute from '../../../utils/format-quote-flow-route';
import { seeTheDifference } from '../constants';

import styles from './see-the-difference.css';

function SeeTheDifference({ pet, policyOptions = {}, quoteId }) {
  const { petAge, petBreedName, petBreedSpecies, petGender, petName } = pet;
  const { prices } = policyOptions;

  const { caseCopy, img, finalCost, pumpkinPays, youPay } =
    seeTheDifference[petBreedSpecies];
  const isCat = petBreedSpecies === CAT;

  return (
    <div className={styles.root}>
      <h2>See the difference a pet insurance plan can make for {petName}!</h2>
      <p className={styles.subtitle}>
        {isCat
          ? "If your cat gets hurt or sick, pet insurance is one the best ways to help give them the best care. Why? Because when cat-astrophes happen, Pumpkin Pet Insurance plans help pet parents say 'yes' to the best care possible by paying back 90% of covered vet bills. Here is a hypothetical example..."
          : "One of the best ways you can provide the best care is by having pet insurance. Why? Because when ruh-rohs happen, Pumpkin Pet Insurance plans help pet parents say 'yes' to the best care possible by paying back 90% of covered vet bills. Here is a hypothetical example..."}
      </p>

      <div className={styles.block}>
        <div className={styles.case}>
          <img alt='' src={img} />

          <div className={styles.copy}>
            <p>
              <b>Case</b>
            </p>
            <p>{caseCopy(petName)}</p>

            <div className={styles.finances}>
              <div>
                <p>
                  <b>Final Covered Vet Bill</b>
                </p>
                <p className={styles.number}>{finalCost}</p>
              </div>

              <div>
                <p>
                  <b>Pumpkin Plan Pays Back**</b>
                </p>
                <p className={styles.number}>{pumpkinPays}</p>
              </div>

              <div>
                <p>
                  <b>You Pay</b>
                </p>
                <p className={styles.number}>{youPay}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {prices ? (
        <>
          <div className={styles.priceBlock}>
            <div className={styles.review}>
              <div className={styles.info}>
                <p>
                  Pumpkin Plan for {petName}:{' '}
                  {formatCurrency(
                    prices.Copay10[isCat ? 'Limit7000' : 'Limit10000'].Deductible500
                  )}
                  {'/mo'}
                </p>
                <p>
                  {petBreedName} &#8226; {petGender} &#8226;{' '}
                  {PET_AGE_OPTIONS.find(option => option.value === petAge)?.label}
                </p>
                <p>
                  Reimbursement Rate: 90%; Annual Limit:{' '}
                  {isCat ? '$7,000' : '$10,000'}; Annual Deductible: $500
                </p>
              </div>

              <a
                href={formatQuoteFlowRoute(quoteId)}
                onClick={() =>
                  analyticsTrack({
                    category: 'Pawsights',
                    event: 'CTA Click',
                    label: 'See the Difference: Quote - True',
                  })
                }
                target='_blank'
                rel='noreferrer'
              >
                <ButtonPrimary className={styles.customize}>
                  Customize Plan
                </ButtonPrimary>
              </a>
            </div>
          </div>

          <p className={styles.legal}>
            **Reimbursement example illustrates 90% reimbursement of a covered vet
            bill, assuming the annual deductible has already been met and the annual
            limit has not yet been satisfied
          </p>
        </>
      ) : (
        <>
          <div className={styles.reimbursementBlock}>
            <div>
              <p className={styles.legal}>
                **Reimbursement example illustrates 90% reimbursement of a covered
                vet bill, assuming the annual deductible has already been met and the
                annual limit has not yet been satisfied
              </p>
            </div>
          </div>

          <div className={styles.redirectBlock}>
            <p>Fetch your pets quote in a tail wag!</p>
            <p>
              Simply add your information so that we can get you an accurate pricing
              for {petName}.
            </p>

            <a
              href={formatQuoteFlowRoute(quoteId)}
              onClick={() =>
                analyticsTrack({
                  category: 'Pawsights',
                  event: 'CTA Click',
                  label: 'See the Difference: Quote - False',
                })
              }
              target='_blank'
              rel='noreferrer'
            >
              <ButtonPrimary className={styles.quote}>
                Get a Free Quote
              </ButtonPrimary>
            </a>
          </div>
        </>
      )}
    </div>
  );
}

export default SeeTheDifference;
