import { analyticsTrack } from '@pumpkincare/portal-analytics';
import { formatSearchFromQuery } from '@pumpkincare/portal-shared';
import { A, Body1 } from '@pumpkincare/portal-ui';

import { PEP, PREVENTIVE_ESSENTIALS_CARD } from '../../constants';
import SectionCard from '../section-card';

import printer from '../../../../../../../public/assets/paw-portal/icons/printer.svg';
import cat from '../../../../../../../public/assets/paw-portal/illustrations/misc/cat-female.png';
import dog from '../../../../../../../public/assets/paw-portal/illustrations/misc/dog-male.png';

export function formatPEPContent(plans, vetId, vetDisplayName) {
  const pepVersion = plans[0].version_number;

  const emailBody = [...PREVENTIVE_ESSENTIALS_CARD.emailBody];
  if (vetDisplayName)
    emailBody[0] = emailBody[0].replace('OUR CLINIC', vetDisplayName);
  emailBody[1] = PREVENTIVE_ESSENTIALS_CARD.emailBodyByPlans(plans);

  return {
    ...PREVENTIVE_ESSENTIALS_CARD,
    emailBody,
    url: `${PREVENTIVE_ESSENTIALS_CARD.url}?${formatSearchFromQuery({
      vetName: vetDisplayName,
      vetId,
      pepVersion,
    })}`,
  };
}

function PEPSectionCard({ plans, printouts, vetId, vetDisplayName }) {
  return (
    <SectionCard
      category={PEP}
      content={formatPEPContent(plans, vetId, vetDisplayName)}
      actionText='View Packages'
      vetName={vetDisplayName}
      handleAction={() =>
        analyticsTrack({
          event: 'Click View Packages from Home Screen | PEP',
          category: PEP,
        })
      }
      tertiaryButton={
        printouts.dog || printouts.cat
          ? {
              tertiaryTitle: 'Print Client Handout',
              tertiaryIcon: printer,
              tertiaryOptions: (
                <>
                  {printouts.dog ? (
                    <A target='_blank' href={printouts.dog}>
                      <img src={dog} />
                      <Body1 style={{ fontSize: '14px' }}>Packages for Dogs</Body1>
                    </A>
                  ) : null}
                  {printouts.cat ? (
                    <A target='_blank' href={printouts.cat}>
                      <img src={cat} />
                      <Body1 style={{ fontSize: '14px' }}>Packages for Cats</Body1>
                    </A>
                  ) : null}
                </>
              ),
            }
          : {}
      }
    />
  );
}

export default PEPSectionCard;
