import { matchPath } from 'react-router';

import { analyticsPage } from '@pumpkincare/portal-analytics';
import { storeObserver } from '@pumpkincare/portal-shared';
import { VET_USER_QUERY } from '@pumpkincare/portal-user';

import {
  loadPatient,
  loadPatientPet,
  loadPatientPetClaims,
  loadPatients,
} from '../../../patients';
import { getPatient } from '../../../patients/selectors';
import loadPatientSearch from '../../../patients/thunks/load-patient-search';
import {
  PATH_PATIENT_PET_CLAIMS,
  PATH_PATIENT_VIEW,
  PATH_PATIENTS_BASE,
} from '../../routing';
import { getTitle } from '../../routing/titles';
import {
  getRouterLocation,
  getRouterLocationPathname,
  getRouterLocationQuery,
} from '../../selectors';

export default function routerStoreObservers(store, queryClient) {
  const { dispatch, getState } = store;

  storeObserver(store, getRouterLocationPathname, pathname => {
    const { page = 1, keyword } = getRouterLocationQuery(getState());
    if (pathname === PATH_PATIENTS_BASE) {
      if (keyword) {
        dispatch(loadPatientSearch(keyword));
      }

      dispatch(loadPatients({ page: page - 1 }));
    }
  });

  storeObserver(store, getRouterLocationPathname, pathname => {
    const matchedPath = matchPath(pathname, PATH_PATIENT_VIEW);
    if (matchedPath) {
      const { patientId } = matchedPath.params;
      dispatch(loadPatient(patientId)).then(() => {
        const { pets } = getPatient(getState());

        pets.map(pet => dispatch(loadPatientPet(patientId, pet)));
      });
    }
  });

  storeObserver(store, getRouterLocationPathname, pathname => {
    const { page = 1 } = getRouterLocationQuery(getState());
    const matchedPath = matchPath(pathname, PATH_PATIENT_PET_CLAIMS);
    if (matchedPath) {
      const { petId } = matchedPath.params;
      dispatch(loadPatientPetClaims(petId, page - 1));
    }
  });

  // only want a listener on pathname, ok if search changes do not trigger a new page track
  storeObserver(store, getRouterLocationPathname, () => {
    const user = queryClient.getQueryData([VET_USER_QUERY]);
    const properties = user ? { email: user.email } : {};
    const location = getRouterLocation(store.getState());

    analyticsPage({ title: getTitle(location), ...properties });
  });
}
