import { useState } from 'react';
import { Link } from 'react-router-dom';

import { analyticsTrack, trackMultiEmailModal } from '@pumpkincare/portal-analytics';
import { useToggleState } from '@pumpkincare/portal-shared';
import {
  Body1,
  Button,
  ButtonPrimary,
  ButtonSecondary,
  Popover,
  Tooltip,
} from '@pumpkincare/portal-ui';

import { PEP } from '../../constants';
import SendMultiEmailModal from '../send-multi-email-modal';

import styles from './section-card.css';

function SectionCard({
  category,
  content,
  handleAction,
  actionText,
  vetName = '',
  tertiaryButton: {
    trackTertiary = () => {},
    tertiaryTitle,
    tertiaryIcon,
    tertiaryOptions,
  },
}) {
  const [isSendMultiEmailModalOpen, toggleIsSendMultiEmailModalOpen] =
    useToggleState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const secondaryButton = (
    <ButtonSecondary className={styles.action} onClick={handleAction}>
      {actionText}
    </ButtonSecondary>
  );

  function handleOpenModal() {
    if (category === PEP) {
      analyticsTrack({
        event: `Click Email to Clients from Home Screen | ${category}`,
        category,
      });
    } else {
      trackMultiEmailModal(category, content.name);
    }

    toggleIsSendMultiEmailModalOpen();
  }

  function handlePopover(bool) {
    setIsPopoverOpen(bool);

    if (bool) trackTertiary();
  }

  return (
    <>
      <div className={styles.module}>
        <div className={styles.leftContainer}>
          <Tooltip
            guideKey={content.guideKey}
            content={content.guideContent}
            classes={{ tooltip: styles.tooltip }}
            leftAligned
          >
            <img src={content.banner} alt='card-banner' className={styles.banner} />
          </Tooltip>
        </div>
        <div className={styles.rightContainer}>
          <h4 className={styles.name}>{content.name}</h4>
          <Body1 className={styles.header}>{content.header}</Body1>
          <div className={styles.buttons}>
            <ButtonPrimary className={styles.button} onClick={handleOpenModal}>
              Email to Clients
            </ButtonPrimary>
            {content.redirectUrl ? (
              <Link to={content.redirectUrl}>{secondaryButton}</Link>
            ) : (
              secondaryButton
            )}

            {tertiaryOptions ? (
              <div onMouseLeave={() => handlePopover(false)}>
                <Button
                  className={styles.popoverButton}
                  onMouseEnter={() => handlePopover(true)}
                >
                  <img src={tertiaryIcon} alt='' /> {tertiaryTitle}
                </Button>
                {isPopoverOpen ? (
                  <Popover className={styles.popover}>{tertiaryOptions}</Popover>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {isSendMultiEmailModalOpen ? (
        <SendMultiEmailModal
          category={category}
          referrer='Home Screen'
          content={{ ...content, vetName }}
          onClose={toggleIsSendMultiEmailModalOpen}
        />
      ) : null}
    </>
  );
}

export default SectionCard;
