import { useEffect, useRef, useState } from 'react';

import {
  analyticsTrack,
  trackMultiEmailSend,
  trackMultiEmailSuccess,
} from '@pumpkincare/portal-analytics';
import { useToggleState } from '@pumpkincare/portal-shared';
import {
  Accordion,
  Body1,
  ButtonPrimary,
  ButtonSecondary,
  Modal,
  MultiEmailField,
} from '@pumpkincare/portal-ui';

import { PEP } from '../../constants';
import useSendMultiEmail from '../../hooks/use-send-multi-email';

import styles from './send-multi-email-modal.css';

import checkIcon from '../../../../../../../public/assets/paw-portal/icons/check-circle-green.svg';
import mailIcon from '../../../../../../../public/assets/paw-portal/icons/mail.svg';

export default function SendMultiEmailModal({
  category,
  content,
  onClose,
  referrer,
}) {
  const emails = useRef([]);
  const { resetHook, sendMultiEmail, statuses } = useSendMultiEmail();
  const [isCloseModalOpen, toggleCloseModal] = useToggleState(false);
  const [hasSubmittedOnce, setHasSubmittedOnce] = useState(false);

  function handleOnClose() {
    if (!emails.current.length || statuses.isSuccess) {
      onClose();
    } else {
      toggleCloseModal();
    }
  }

  function handleEmailChange(newEmails) {
    emails.current = newEmails;
  }

  function handleSubmitEmails(e) {
    if (category === PEP) {
      analyticsTrack({
        event: `Click Email Send from ${referrer} | ${category}`,
        category,
      });
    } else {
      trackMultiEmailSend(category, content.emailName);
    }

    setHasSubmittedOnce(true);
    if (emails.current.length && emails.current.every(({ valid }) => valid)) {
      sendMultiEmail(e, emails.current, content);
    }
  }

  function resetModal() {
    handleEmailChange([]);
    setHasSubmittedOnce(false);
    resetHook();
  }

  useEffect(() => {
    if (statuses.isSuccess) {
      if (category === PEP) {
        analyticsTrack({
          event: `Viewed Email Success from ${referrer} | ${category}`,
          category,
        });
      } else {
        trackMultiEmailSuccess(category, content.emailName);
      }
    }
  }, [statuses, content, category, referrer]);

  return (
    <Modal onClose={handleOnClose} classes={{ content: styles.content }}>
      {statuses.isSuccess ? (
        <>
          <h4 className={styles.title}>
            <img src={checkIcon} alt='' className={styles.check} />
            Success!
          </h4>
          <Body1 className={styles.message}>
            {content.emailName} is on its way to the email addresses you selected.
          </Body1>
          <ButtonSecondary onClick={resetModal} className={styles.button}>
            Send More
          </ButtonSecondary>
        </>
      ) : (
        <>
          <h4 className={styles.title}>Send {content.emailName}</h4>
          <div className={styles.card}>
            <img src={mailIcon} className={styles.icon} alt='' />
            <img src={content.banner} className={styles.banner} alt='Guide Banner' />
            <Accordion
              staticContent={
                <Body1 style={{ marginBottom: '10px' }}>
                  <div dangerouslySetInnerHTML={{ __html: content.emailBody[0] }} />
                </Body1>
              }
              closedLabel='Show More'
              isFixedHeader={false}
              openLabel='Show Less'
              actionPosition='footer'
              classes={{
                container: styles.accordionContainer,
                content: styles.accordionContent,
              }}
            >
              {content.emailBody.map((text, index) => (
                <Body1 key={`mail-body-${index}`} style={{ marginBottom: '10px' }}>
                  <div dangerouslySetInnerHTML={{ __html: text }} />
                </Body1>
              ))}
            </Accordion>
          </div>

          <MultiEmailField
            onChange={handleEmailChange}
            classes={{ container: styles.field }}
            hasSubmittedOnce={hasSubmittedOnce}
          />
          <ButtonPrimary
            type='submit'
            isLoading={statuses.isLoading}
            className={styles.button}
            onClick={handleSubmitEmails}
          >
            Send
          </ButtonPrimary>
          {statuses.isError ? (
            <Body1 className={styles.error}>
              Sorry, something went wrong! Please try again or check back later.
            </Body1>
          ) : null}
        </>
      )}

      {isCloseModalOpen ? (
        <Modal disabled hasCloseIcon={false} classes={{ container: styles.modal }}>
          <h4>Are you sure you want to leave this page?</h4>
          <Body1 style={{ marginTop: '20px' }}>
            You will lose your filled emails.
          </Body1>
          <div className={styles.modalButtons} style={{ marginTop: '20px' }}>
            <ButtonSecondary className={styles.modalButton} onClick={onClose}>
              Leave
            </ButtonSecondary>
            <ButtonPrimary className={styles.modalButton} onClick={toggleCloseModal}>
              Keep Editing
            </ButtonPrimary>
          </div>
        </Modal>
      ) : null}
    </Modal>
  );
}
