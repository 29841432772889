import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { analyticsTrack, setAnonymousId } from '@pumpkincare/portal-analytics';
import { usePolicyOptions } from '@pumpkincare/portal-plans';
import {
  getQuoteFirstPetId,
  getQuotePet,
  getQuotePetBreedCode,
  getQuotePetName,
  getQuoteVetId,
  useQuote,
} from '@pumpkincare/portal-quotes';
import { useBreedReports, useDelayedLoading } from '@pumpkincare/portal-shared';
import { getVetDisplayName, useVet } from '@pumpkincare/portal-vets';

import PawsightsFooter from '../pawsights-footer';
import PawsightsNavBar from '../pawsights-nav-bar';
import CareTips from './care-tips';
import Contact from './contact';
import GetToKnow from './get-to-know';
import Header from './header';
import PawsightsLoader from './pawsights-loader';
import Resources from './resources';
import SeeTheDifference from './see-the-difference';
import Watchouts from './watchouts';

import styles from './pawsights-report.css';

function PawsightsReport() {
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const paramsQuoteId = params.get('quote_id');
  const paramsVetId = params.get('vet_id');
  const paramsBreedCode = params.get('breed_code');
  const paramsPetName = params.get('pet_name');

  const { data: quoteData, isFetching: isQuoteFetching } = useQuote(
    {
      quoteId: paramsQuoteId,
    },
    {
      onSuccess: ({ trackingId }) => {
        if (trackingId) setAnonymousId(trackingId);
      },
    }
  );

  const petId = getQuoteFirstPetId(quoteData);
  const pet = getQuotePet(quoteData)(petId);
  const petName = getQuotePetName(quoteData)(petId) || paramsPetName;
  const vetId = getQuoteVetId(quoteData) || paramsVetId;
  const breedCode = getQuotePetBreedCode(quoteData)(petId) || paramsBreedCode;

  const { data: vetData, isFetching: isVetFetching } = useVet(vetId);
  const vetName = getVetDisplayName(vetData);

  const { data: breedReportsData, isFetching: isBreedReportsDataFetching } =
    useBreedReports(breedCode);

  const { data: policyOptionsData, isFetching: isPolicyOptionsFetching } =
    usePolicyOptions({ quoteId: paramsQuoteId, petId });

  const messages = [
    `Fetching ${petName}'s breed info...`,
    `Retrieving breed traits...`,
    `Digging for breed health watchouts...`,
    `Chasing helpful care tips...`,
    `SQUIRREL... okay no back to ${petName}'s report`,
  ];

  const isLoading = useDelayedLoading(
    [
      isQuoteFetching,
      isBreedReportsDataFetching,
      isVetFetching,
      isPolicyOptionsFetching,
    ],
    messages.length * 1000
  );

  useEffect(() => {
    if (!isLoading) {
      analyticsTrack({
        category: 'Pawsights',
        event: 'Report impression',
      });
    }
  }, [isLoading]);

  return (
    <>
      <PawsightsNavBar vetName={vetName} isReport={!isLoading} />

      {isLoading ? (
        <PawsightsLoader messages={messages} />
      ) : (
        <div className={styles.root}>
          <div>
            <Header
              pet={pet}
              policyOptions={policyOptionsData}
              quoteId={paramsQuoteId}
            />
          </div>
          <section>
            <GetToKnow
              breedReport={breedReportsData}
              pet={pet}
              vet={vetData}
              quoteId={paramsQuoteId}
              hasPrices={!!policyOptionsData?.prices}
            />
          </section>

          <section>
            <CareTips
              breedReport={breedReportsData}
              pet={pet}
              quoteId={paramsQuoteId}
              hasPrices={!!policyOptionsData?.prices}
            />
          </section>

          <section>
            <Watchouts pet={pet} vetName={vetName} breedReport={breedReportsData} />
          </section>

          <SeeTheDifference
            pet={pet}
            policyOptions={policyOptionsData}
            quoteId={paramsQuoteId}
          />

          <Resources petBreedSpecies={pet.petBreedSpecies} />

          <Contact vetName={vetName} />

          <PawsightsFooter />
        </div>
      )}
    </>
  );
}

export default PawsightsReport;
