import { Fragment } from 'react';
import classNames from 'classnames';

import { analyticsTrack } from '@pumpkincare/portal-analytics';
import { transformPetAgeToMonths } from '@pumpkincare/portal-plans';
import { CAT } from '@pumpkincare/portal-shared';
import { Accordion, ButtonPrimary } from '@pumpkincare/portal-ui';

import formatQuoteFlowRoute from '../../../utils/format-quote-flow-route';
import {
  formatBreedName,
  getToKnowTraits,
  planAheadAside,
  rankingMapping,
} from '../constants';

import styles from './get-to-know.css';

import siren from '../../../../../../../../public/assets/paw-portal/icons/siren.svg';

export function formatCopy(
  { petBreedName, petBreedSpecies, petGender, petName },
  { vetName = 'your veterinarian' }
) {
  const pronoun = petGender === 'female' ? 'her' : 'him';
  const catGrowth = 'from kitten to adult cat to senior';
  const dogGrowth = 'from a pup to an adult and senior';
  const isCat = petBreedSpecies === CAT;

  const defaultCopy = {
    headerCopy: `Get to know your ${petBreedName}`,
    headerSubtitle: `In ${petName}’s Pawsights Report, we’re giving you as much helpful info as we can about your ${petBreedName}, so you can help ${pronoun} get the best care fur years to come. We love that you’re prioritizing regular vet visits to ${vetName} and taking time to learn more about ${petName}’s unique needs – what a lucky ${
      isCat ? 'kitty' : 'pup'
    }!`,
    lifestageSubtitle: `${petBreedName}s age gracefully, and here are the key development milestones to be aware of as they grow ${
      isCat ? catGrowth : dogGrowth
    }!`,
  };

  if (petBreedName === 'Mixed Cat')
    return {
      ...defaultCopy,
      headerCopy: `Get to know your ${formatBreedName(petBreedName)}`,
      lifestageSubtitle: `Here are the key development milestones to be aware of as your mixed-breed cat grows ${catGrowth}!`,
    };

  if (
    ['Domestic Shorthair', 'Domestic Mediumhair', 'Domestic Longhair'].some(
      breed => breed === petBreedName
    )
  )
    return {
      headerCopy: `${defaultCopy.headerCopy} cat`,
      headerSubtitle: defaultCopy.headerSubtitle,
      lifestageSubtitle: `Here are the key development milestones to be aware of as your ${petBreedName} cat grows ${catGrowth}!`,
    };

  if (
    [
      'Small Mix (up to 10 pounds)',
      'Small Mix (11-25 pounds)',
      'Medium Mix (26-50 pounds)',
      'Large Mix (51-100 pounds)',
      'Giant Mix (over 100 pounds)',
    ].some(breed => breed === petBreedName)
  ) {
    return {
      headerCopy: `Get to know your ${formatBreedName(petBreedName)}`,
      headerSubtitle: `In ${petName}’s Pawsights Report, we’re giving you as much helpful info as we can about your one-of-a-kind ${formatBreedName(
        petBreedName
      )} so you can help ${pronoun} get the best care fur years to come. We love that you’re prioritizing regular vet visits to ${vetName} and taking time to learn more about ${petName}’s unique needs – what a lucky pup!`,
      lifestageSubtitle: `Here are a few key milestones in ${petName}’s growth and development to be aware of as they grow up ${dogGrowth}!`,
    };
  }

  return defaultCopy;
}

function GetToKnow({ breedReport, hasPrices, pet, quoteId, vet }) {
  const {
    why_we_love_them: whyWeLoveThem,
    breed_characteristics: breedCharacteristics,
    breed_mapping: breedMapping,
    lifestages,
  } = breedReport;
  const { petAge, petBreedSpecies, petName } = pet;
  const petAgeMonths = transformPetAgeToMonths(petAge);

  const { headerCopy, headerSubtitle, lifestageSubtitle } = formatCopy(pet, vet);

  const aside = planAheadAside[petBreedSpecies](petName);
  const traits = getToKnowTraits[petBreedSpecies];

  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <h2>{headerCopy}</h2>
        <p>{headerSubtitle}</p>
        <p className={styles.digIn}>Let’s dig in...</p>

        {whyWeLoveThem ? (
          <div className={styles.reasons}>
            <h3>Why we love them...</h3>

            {whyWeLoveThem.map((reason, idx) => (
              <div key={reason.title} className={styles.reason}>
                <h4>{idx + 1}</h4>

                <div>
                  <p>
                    <b>{reason.title}</b>
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : null}

        {breedCharacteristics ? (
          <div className={styles.traits}>
            {traits.map(trait =>
              breedCharacteristics[trait.key] ? (
                <div className={styles.trait} key={trait.key}>
                  <img alt='' src={trait.icon} />
                  <p>
                    <b>{trait.title}</b>
                  </p>
                  <p>{rankingMapping[breedCharacteristics[trait.key]]}</p>
                </div>
              ) : null
            )}
          </div>
        ) : null}

        {lifestages ? (
          <div className={styles.lifestages}>
            <h3>{petName}'s life stages</h3>
            <p>{lifestageSubtitle}</p>

            {lifestages.map(lifestage => {
              const isCurrentAge =
                petAgeMonths >= lifestage.min_age_months &&
                petAgeMonths < lifestage.max_age_months;
              const showLifestageImages =
                lifestage.img &&
                (!breedMapping ||
                  (pet.petBreedType === 'Mixed' &&
                    pet.petBreedName.includes(
                      decodeURI(breedMapping).split('~')[2]
                    )));
              return (
                <Fragment key={lifestage.title}>
                  <div className={styles.mobileOnly}>
                    <div
                      className={classNames(styles.lifestage, {
                        [styles.activeAge]: isCurrentAge,
                      })}
                    >
                      <Accordion
                        staticContent={
                          <p className={styles.lifestageTitle}>
                            <b>{lifestage.title} </b>({lifestage.age_range})
                            {isCurrentAge ? <span>{petName} is here</span> : null}
                          </p>
                        }
                        defaultValue={isCurrentAge}
                        classes={{
                          container: styles.accordionContainer,
                          content: styles.accordionContent,
                        }}
                      >
                        <>
                          {showLifestageImages ? (
                            <img
                              alt=''
                              src={lifestage.img}
                              className={styles.lifestageImg}
                            />
                          ) : null}

                          <p>{lifestage.text}</p>
                        </>
                      </Accordion>
                    </div>
                  </div>

                  <div className={styles.desktopOnly}>
                    <div
                      className={classNames(styles.lifestage, {
                        [styles.activeAge]: isCurrentAge,
                      })}
                    >
                      {showLifestageImages ? (
                        <img
                          alt=''
                          src={lifestage.img}
                          className={styles.lifestageImg}
                        />
                      ) : null}

                      <div className={styles.lifestageCopy}>
                        <p className={styles.lifestageTitle}>
                          <b>{lifestage.title}</b> ({lifestage.age_range})
                        </p>
                        <p>{lifestage.text}</p>

                        {isCurrentAge ? <span>{petName} is here</span> : null}
                      </div>
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </div>
        ) : null}
      </div>

      <div className={styles.right}>
        <div className={styles.aside}>
          <div className={styles.info}>
            {aside.map(item => (
              <p key={item}>{item}</p>
            ))}

            <a
              href={formatQuoteFlowRoute(quoteId)}
              onClick={() =>
                analyticsTrack({
                  category: 'Pawsights',
                  event: 'CTA Click',
                  label: `Get To Know: Quote - ${hasPrices ? 'True' : 'False'}`,
                })
              }
              target='_blank'
              rel='noreferrer'
            >
              <ButtonPrimary className={styles.button}>
                Fetch {petName}'s Quote
              </ButtonPrimary>
            </a>
          </div>

          <p className={styles.ribbon}>Plan ahead for {petName}'s care</p>
          <img alt='' src={siren} />
        </div>
      </div>
    </div>
  );
}

export default GetToKnow;
